import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { Table, Space, Button, Row, Col, Form, Select, Input } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import SimplePagination from "../../components/simplePagination";
import * as MitraSelectors from "../../state/mitra/mitra.selector";
import * as MitraActions from "../../state/mitra/mitra.action";
import { getBusinessPartner } from "../../state/service/service.action";
import { selectBusinessPartners } from "../../state/service/service.selector";
import { IMitra } from "../../state/mitra/mitra.interface";
import { ProfileImage } from './style';

const {Option} = Select

const Mitra = (props: any) => {
  const dispatch = useDispatch();
  const getMitra = (params) => dispatch(MitraActions.getMitra.request(params))

  const [currentPage, setCurrentPage] = useState<number>(1);
  const businessPartners = useSelector(selectBusinessPartners);
  const [selectedFilter, setSelectedFilter] = useState('phone')
  const [search, setSearch] = useState('')

  const columnsBusinessPartner = [
    {
      title: '',
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl: string) => {
        return <ProfileImage>
            <img src={imageUrl} alt="image_profile"></img>
          </ProfileImage>
      }
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User Preference",
      dataIndex: "userPreference",
      key: "userPreference",
      render: (preference: string) => {
        if (preference === 'f') {
          return <p>perempuan</p>
        } else if (preference === 'm') {
          return <p>laki-laki</p>
        } else {
          return <p>-</p>
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        if (status === "active") {
          return <CheckCircleTwoTone />;
        } else {
          return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (mitra: IMitra) => {
        return (
          <Space size="small">
            <a
              onClick={() => {
                props.history.push(`/mitra/${mitra.code}`);
              }}
            >
              Detail
            </a>
          </Space>
        );
      },
    },
  ];

  const columnsAdmin = [
    {
      title: '',
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl: string) => {
        return (
          <ProfileImage>
            <img src={imageUrl} alt="image_profile"></img>
          </ProfileImage>
        )
      }
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Business Partner",
      dataIndex: "businessPartnerCode",
      key: "businessPartnerCode",
      render: (businessPartnerCode: string) => {
        return (
          <p>
            {
              businessPartners.find(
                (businessPartner) =>
                  businessPartner.code === businessPartnerCode
              )?.name
            }
          </p>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User Preference",
      dataIndex: "userPreference",
      key: "userPreference",
      render: (preference: string) => {
        if (preference === 'f') {
          return <p>perempuan</p>
        } else if (preference === 'm') {
          return <p>laki-laki</p>
        } else {
          return <p>-</p>
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        if (status === "active") {
          return <CheckCircleTwoTone />;
        } else {
          return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (mitra: IMitra) => {
        return (
          <Space size="small">
            <a
              onClick={() => {
                props.history.push(`/mitra/${mitra.code}`);
              }}
            >
              Detail
            </a>
          </Space>
        );
      },
    },
  ];

  const columns =
    localStorage.getItem("role") === "admin"
      ? columnsAdmin
      : columnsBusinessPartner;

  const ColProps = {
    xs: 24,
    sm: 12,
    style: {
      marginBottom: 16,
    },
  };

  const filterOptions = [
    {
      value: "phone",
      label: "Phone",
    },
    {
      value: "name",
      label: "Name",
    },
    {
      value: "code",
      label: "Code",
    },
  ];

  let onAdd = () => {
    props.history.push("/mitra/registration");
  };

  useEffect(() => {
    getMitra({})
    if (localStorage.getItem("role") === "admin") {
      dispatch(
        getBusinessPartner.request({
          page: 1,
        })
      );
    }
  }, []);

  useEffect(() => {
    getMitra({
      page: currentPage,
    })
  }, [currentPage]);

  const onSearch = (e) => {
    getMitra({
      page: currentPage,
      filter: selectedFilter,
      value: e    
    })
  }

  const onClear = () => {
    setSearch('')
    getMitra({
      page: currentPage,
      filter: '',
      value: '' 
    })
  }

  return (
    <>
      <h1>MITRA</h1>
      <Row align='middle' gutter={24} justify='space-between'>
        <Col>
          <Row align='middle' gutter={24}>
            <Col>
              <h3>Filter</h3>
            </Col>
            <Col>
              <Select
                placeholder='Select filter'
                defaultValue={selectedFilter}
                value={selectedFilter}
                onSelect={(e) => setSelectedFilter(e)}
              >
                {
                  filterOptions.map((option) => (
                    <Option  key={ option.value } value={ option.value }>
                      {option.label}
                    </Option>
                  ))
                }
              </Select>
            </Col>
            <Col>
              <Input.Search 
                placeholder={
                  selectedFilter === 'phone' 
                  ? 'Search phone number'
                  : selectedFilter === 'name'
                  ? 'Search name'
                  : 'Search code'
                }
                onSearch={onSearch}
                value={search}
                onChange={e => setSearch(e.target.value)}
              ></Input.Search>
            </Col>
            <Col>
              <Button onClick={onClear}>Clear</Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle" justify="space-between">
            <Button onClick={onAdd}>Create Mitra</Button>
          </Row>
        </Col>
      </Row>
      <Table
        loading={useSelector(MitraSelectors.selectLoading)}
        columns={columns}
        dataSource={useSelector(MitraSelectors.selectMitraList)}
        scroll={isMobile ? { x: "80vw" } : undefined}
        pagination={false}
        style={{marginTop: '20px'}}
      />
      <SimplePagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        dataLength={useSelector(MitraSelectors.selectMitraList).length}
      />
    </>
  );
};

export default Mitra;
