import { roleAPIHelper, customFetch } from "../helpers";
import { IMitra, ITransactionMitra } from "../state/mitra/mitra.interface";
import { mapOrderResponse } from './order.service';

const mapMitraResponse = (mitra) => {
  return {
    code: mitra.code,
    name: mitra.name,
    phone: mitra.phone,
    status: mitra.status,
    businessPartnerCode: mitra.business_partner_code,
    depositAccount: mitra.deposit_accounts && mitra.deposit_accounts.length > 0
      ? mitra.deposit_accounts.map(depositAcc => mapDepositAcc(depositAcc))
      : {
        bankName: '',
        bankNumber: ''
      },
    emergencyContact: mitra.emergency_contacts,
    fullAddress: mitra.full_address,
    gender: mitra.gender,
    imageUrl: mitra.image,
    kkImage: mitra.kk_image,
    ktpImage: mitra.ktp_image,
    ktpNumber: mitra.ktp_number,
    latitude: mitra.latitude,
    longitude: mitra.longitude,
    c_at: mitra.c_at,
    userPreference: mitra.user_preference,
    skckImage: mitra.skck_image
  } as IMitra;
};

const mapDepositAcc = (depositAcc) => ({
  bankName: depositAcc.bank_name,
  bankNumber: depositAcc.bank_number
})

export const getMitra = async (params: { limit: number; skip: number, filter?: string, value?: string }) => {
  try {
    let url = `${process.env.REACT_APP_ACCOUNT_API}/api/v1/${roleAPIHelper()}/mitra?limit=${params.limit}&skip=${params.skip}&filter=${params.filter && params.value && params.value.length > 0 ? params.filter : ''}&value=${params.value ? params.value : ''}`;
    const response: any = await customFetch(url, "GET");

    if (response.data) {
      response.data.results = response.data.results.map((mitra) =>
        mapMitraResponse(mitra)
      );
    }


    return response;
  } catch (error) {
    throw error;
  }
};

export const getMitraByCode = async (mitraCode: string) => {
  try {
    let url = `${process.env.REACT_APP_ACCOUNT_API}/api/v1/${roleAPIHelper()}/mitra/${mitraCode}`;
    const response: any = await customFetch(url, "GET");
    response.data = mapMitraResponse(response.data);

    return response;
  } catch (error) {
    throw error;
  }
};

const mapServiceChildResponse = (serviceChild: any[]) => {
  return serviceChild.map((service: any) => {
    return {
      name: service.name,
      code: service.service_code,
    };
  });
};

export const getMitraServices = async (mitraCode: string) => {
  try {
    let url = `${process.env.REACT_APP_ACCOUNT_API}/api/v1/${roleAPIHelper()}/mitra-service/mitras/${mitraCode}`;
    const response: any = await customFetch(url, "GET");
    let responseJson = response;

    responseJson.data.results = mapServiceChildResponse(
      responseJson.data.results
    );

    return responseJson;
  } catch (error) {
    throw error;
  }
};

export const insertMitraService = async (payload: {
  mitraCode: string;
  serviceCode: string;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/mitra-service`,
      "POST",
      {
        mitra_code: payload.mitraCode,
        service_code: payload.serviceCode,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeMitraService = async (payload: {
  mitraCode: string;
  serviceCode: string;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/mitra-service`,
      "DELETE",
      {
        mitra_code: payload.mitraCode,
        service_code: payload.serviceCode,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const mapMitraToApi = (mitraForm: any) => {
  return {
    name: mitraForm.name,
    phone: mitraForm.phone,
    gender: mitraForm.gender,
    image: mitraForm.image,
    ktp_number: mitraForm.ktpNumber,
    ktp_image: mitraForm.ktpImage,
    kk_image: mitraForm.kkImage,
    skck_image: mitraForm.skckImage
      ? mitraForm.skckImage
      : "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png",
    full_address: mitraForm.fullAddress,
    deposit_accounts: [
      { bank_name: mitraForm.bankName, bank_number: mitraForm.bankNumber },
    ],
    emergency_contacts: [
      {
        name: mitraForm.emergencyContactName,
        phone: mitraForm.emergencyContactPhone,
      },
    ],
    dob: mitraForm.dob ? mitraForm.dob : "2020-10-11",
    business_partner_code: mitraForm.businessPartnerCode,
    latitude: mitraForm.latitude,
    longitude: mitraForm.longitude,
    password: '12345678'
  };
};

export const editMitra = async (mitraForm: any) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_ACCOUNT_API}/api/v1/admin/mitra/${mitraForm.code}`,
      "PUT",
      mapMitraToApi(mitraForm)
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const registerMitra = async (mitraForm: any) => {
  try {
    let url = `${process.env.REACT_APP_ACCOUNT_API}/api/v1/${roleAPIHelper()}/mitra`;

    const response: any = await customFetch(
      url,
      "POST",
      mapMitraToApi(mitraForm)
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getTransaction = async (params: {
  transactionStatus: string;
  limit: number;
  skip: number;
  type: string;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/transactions?status=${params.transactionStatus}&type=${params.type}&limit=${params.limit}&skip=${params.skip}`,
      "GET"
    );

    response.data.results = response.data.results.map(transaction => mapTransactionResponse(transaction))

    return response;
  } catch (error) {
    throw error;
  }
};

export const editTransactionStatus = async (transaction: {
  code: string;
  notes: string;
  status: string;
  amount: number;
  receiptImage?: string;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/admin/transactions`,
      "PUT",
      mapTransactionToApi(transaction)
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const mapTransactionResponse = (transaction) => {
  return {
    code: transaction.code,
    name: transaction.name,
    amount: transaction.amount,
    notes: transaction.notes,
    status: transaction.status,
    receiptImage: transaction.receipt_image,
    type: transaction.type,
    mitra: transaction.mitra,
    order: transaction.order,
    payment: transaction.payment,
    c_at: transaction.c_at
  } as ITransactionMitra
};

const mapTransactionToApi = (transaction) => {
  let transactionToApi = {
    code: transaction.code,
    notes: transaction.notes,
    status: transaction.status,
    amount: transaction.amount,
  }
  if (!transaction.receiptImage) {
    return transactionToApi
  } else {
   return {
    ...transactionToApi,
    receipt_image: transaction.receiptImage
   }
  }
}

export const getMitraBalance = async(params: {
  mitraCode: string;
}) => {
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/${roleAPIHelper()}/transactions/mitra/${params.mitraCode}/balance`,
      "GET",
    );

    response.data = {
      amount: response.data.amount,
      mitraCode: response.data.mitra_code
    }

    return response;
  } catch (error) {
    throw error;
  }
}

export const getOrderOnGoing = async(params: {
  mitraCode: string,
  limit: number,
  skip: number
}) => {
  const transactionStatus = ['pending', 'looking_for_mitra', 'mitra_assigned', 'mitra_on_the_way', 'start_service', 'finish_service', 'pending_payment'];
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/${roleAPIHelper()}/orders?status=${transactionStatus.join('%26')}&limit=${params.limit}&skip=${params.skip}&mitra_code=${params.mitraCode}`,
      "GET",
    );
    response.data = response.data.results.map(order => mapOrderResponse(order))
    return response;
  } catch (error) {
    throw error;
  }
}

export const getOrderHistory = async(params: {
  mitraCode: string,
  limit: number,
  skip: number
}) => {
  const transactionStatus = ['cancel', 'done'];
  try {
    const response: any = await customFetch(
      `${process.env.REACT_APP_TRANSACTION_API}/api/v1/${roleAPIHelper()}/orders?status=${transactionStatus.join('%26')}&limit=${params.limit}&skip=${params.skip}&mitra_code=${params.mitraCode}`,
      "GET",
    );
    response.data = response.data.results.map(order => mapOrderResponse(order))
    return response;
  } catch (error) {
    throw error;
  }
}